import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _toConsumableArray from "/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray";
import Mock from 'mockjs';
import { param2Obj } from "../src/utils";
import user from "./user";
import table from "./table";
var mocks = [].concat(_toConsumableArray(user), _toConsumableArray(table)); // for front mock
// please use it cautiously, it will redefine XMLHttpRequest,
// which will cause many of your third-party libraries to be invalidated(like progress event).

export function mockXHR() {
  // mock patch
  // https://github.com/nuysoft/Mock/issues/300
  Mock.XHR.prototype.proxy_send = Mock.XHR.prototype.send;

  Mock.XHR.prototype.send = function () {
    if (this.custom.xhr) {
      this.custom.xhr.withCredentials = this.withCredentials || false;

      if (this.responseType) {
        this.custom.xhr.responseType = this.responseType;
      }
    }

    this.proxy_send.apply(this, arguments);
  };

  function XHR2ExpressReqWrap(respond) {
    return function (options) {
      var result = null;

      if (respond instanceof Function) {
        var body = options.body,
            type = options.type,
            url = options.url; // https://expressjs.com/en/4x/api.html#req

        result = respond({
          method: type,
          body: JSON.parse(body),
          query: param2Obj(url)
        });
      } else {
        result = respond;
      }

      return Mock.mock(result);
    };
  }

  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = mocks[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var i = _step.value;
      Mock.mock(new RegExp(i.url), i.type || 'get', XHR2ExpressReqWrap(i.response));
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return != null) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }
} // for mock server

var responseFake = function responseFake(url, type, respond) {
  return {
    url: new RegExp("/mock".concat(url)),
    type: type || 'get',
    response: function response(req, res) {
      res.json(Mock.mock(respond instanceof Function ? respond(req, res) : respond));
    }
  };
};

export default mocks.map(function (route) {
  return responseFake(route.url, route.type, route.response);
});