import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth'; // create an axios instance

var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  withCredentials: false,
  // send cookies when cross-domain requests
  timeout: 5000 // request timeout

}); // request interceptor

service.interceptors.request.use(function (config) {
  // config.headers['Access-Control-Allow-origin'] = "\*";
  // do something before request is sent
  config.headers['accessToken'] = process.env.ACCESS_TOKEN; // if (store.getters.token) {
  //   // let each request carry token
  //   // ['X-Token'] is a custom headers key
  //   // please modify it according to the actual situation
  //   config.headers['X-Token'] = getToken()
  // }
  // config.headers['X-Token'] = getToken()

  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  console.log('axios rsp', response);
  var res = response.data; // console.log(response)
  // if the custom code is not 20000, it is judged as an error.

  if (response.status !== 200) {
    console.log('exception', response);
    Message({
      message: res.message || 'Error',
      type: 'error',
      duration: 5 * 1000
    }); // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;

    if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      // to re-login
      MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
        confirmButtonText: 'Re-Login',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        store.dispatch('user/resetToken').then(function () {
          location.reload();
        });
      });
    }

    return Promise.reject(new Error(res.message || 'Error'));
  } else {
    return res;
  }
}, function (error) {
  console.log('err' + error); // for debug

  Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;